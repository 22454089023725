import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import {
    FeatureList,
    FeatureListProps
} from 'common/components/organisms/FeatureList'
import {
    Information,
    InformationProps
} from 'common/components/organisms/Information'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    Resources,
    ResourcesProps
} from 'common/components/organisms/Resources'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface ProductLifecareProps {
    benefits: BenefitsProps
    information: InformationProps
    jumbotron: JumbotronProps
    featureList: FeatureListProps
    featureList2: FeatureListProps
    layout: LayoutProps
    resources: ResourcesProps
}

export const ProductLifecare: React.FC<ProductLifecareProps> = ({
    benefits,
    information,
    jumbotron,
    featureList,
    featureList2,
    layout,
    resources
}) => {
    const desktop = useDesktop()

    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <Wrapper>
                <Spacer />
                {benefits?.title && (
                    <>
                        <Benefits {...benefits} title={benefits?.title} />
                        <Spacer variant={desktop ? 'lg' : 'md'} />
                    </>
                )}
                {featureList.title && (
                    <FeatureList variant='top' {...featureList} />
                )}
                {information.title && (
                    <>
                        <Spacer variant='md' />
                        <Information {...information} />
                    </>
                )}
                {resources.title && (
                    <>
                        <Resources {...resources} />
                        <Spacer />
                    </>
                )}
                {featureList2.title && (
                    <>
                        <FeatureList variant='left' {...featureList2} />
                        <Spacer variant={desktop ? 'lg' : 'md'} />
                    </>
                )}
            </Wrapper>
        </Layout>
    )
}
