import { FeatureListProps } from 'common/components/organisms/FeatureList'
import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { ProductLifecare } from 'common/components/templates/ProductLifeCare'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface ProductLifecarePageProps {
    pageContext: any
}

export const ProductLifecarePage: React.FC<ProductLifecarePageProps> = ({
    pageContext
}) => {
    return <ProductLifecare {...getProductLifecareData(pageContext)} />
}

export default ProductLifecarePage

export const getProductLifecareData = (data: any) => ({
    layout: getLayoutData(data),
    benefits: {
        benefits: (data.threecards__cards || []).map((card: any) => ({
            ...card,
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            }
        })),
        subtitle: data.threecards__subtitle,
        title: data.threecards__title
    },
    featureList: {
        text: data.featurelist__text,
        cta: data.featurelist__cta,
        callToAction: {
            analyticsId: data.featurelist__cta_analyticsid,
            label: data.featurelist__cta_label,
            [`${data.call_to_action__iconlocation}Icon`]: data.featurelist__cta_icon,
            url: data.call_to_action__url,
            variant: data.call_to_action__variant,
            noSpacer: true
        },
        image: data.featurelist__image,
        title: data.featurelist__title,
        features: data.featurelist__features,
        variant: data.featurelist2__title
            ? 'right'
            : (undefined as FeatureListProps['variant'])
    },
    featureList2: {
        text: data.featurelist2__text,
        cta: data.featurelist2__cta,
        callToAction: {
            analyticsId: data.featurelist2__cta_analyticsid,
            label: data.featurelist2__cta_label,
            [`${data.call_to_action__iconlocation}Icon`]: data.featurelist2__cta_icon,
            url: data.featurelist2__cta_url,
            variant: data.featurelist2__cta_variant,
            noSpacer: true
        },
        image: data.featurelist2__image,
        title: data.featurelist2__title,
        features: data.featurelist2__features
    },
    information: {
        title: data.information__title,
        text: (data.information__paragraphs || []).map(
            (paragraph: { text: string }) => paragraph.text
        )
    },
    jumbotron: getJumbotronData(data),
    resources: {
        title: data.resources__title,
        text: data.resources__text,
        downloads: data.resources__downloads
    }
})
